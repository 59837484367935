import { useEffect, useState } from 'react';
import { ToastStyle } from '../../../styles/components/common/ui';
export interface IToastProps {
  children: React.ReactNode;
  type: 'success' | 'danger' | 'neutral';
  icon?: React.ReactElement;
  show?: boolean;
  onDismiss: () => void;
}

const Toast = (props: IToastProps) => {
  useEffect(() => {
    if (props.show === true) {
      setTimeout(() => {
        props.onDismiss();
      }, 2000);
    }
  }, [props, props.show]);

  if (props.show) {
    return (
      <div
        className={`${ToastStyle['toast']} ${ToastStyle[props.type]} ${
          ToastStyle['animate-in']
        }`}>
        {props.icon && (
          <div className={ToastStyle['toast-icon']}>{props.icon}</div>
        )}
        <div className={ToastStyle['toast-content']}>{props.children}</div>
      </div>
    );
  } else {
    return null;
  }
};

export default Toast;
