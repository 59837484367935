import React from 'react';
import FormLayout from '../layout/FormLayout';
interface InputProps {
  type: 'text' | 'password' | 'email';
  name: string;
  id: string;
  placeholder?: string;
  value?: string;
  label?: string | React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  error?: string;
  defaultValue?: string;
  wrapper?: boolean;
  styles?: any;
  children?: React.ReactNode;
  ref?: any;
}
const Textarea = ({
  styles,
  name,
  id,
  placeholder,
  value,
  onChange,
  disabled = false,
  error,
  defaultValue,
  label,
  children,
  ref,
}: InputProps) => {
  return (
    <>
      <FormLayout
        styles={styles}
        error={error}
        label={children ? children : label}>
        <textarea
          ref={ref}
          name={name}
          id={id}
          style={
            error
              ? {
                  ...styles,
                  backgroundColor: '#FEF1F2',
                  borderColor: '#E18E96',
                }
              : { ...styles }
          }
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={onChange}
          defaultValue={defaultValue}
        />
      </FormLayout>
    </>
  );
};

export default Textarea;
