import type { NextApiRequest, NextApiResponse } from 'next';
import commonTranslations from '../../scripts/common-translations.json';
import contactMethods from '../../scripts/contact-methods.json';
import cookiesPopUp from '../../scripts/cookies-pop-up.json';
import countryNames from '../../scripts/country-names.json';
import currencies from '../../scripts/currencies.json';
import entitiesStatus from '../../scripts/entities-status.json';
import languages from '../../scripts/languages.json';
import mainFooter from '../../scripts/main-footer.json';
import mainNavBar from '../../scripts/main-nav-bar.json';

const handler = async (req: NextApiRequest, res: NextApiResponse<any>) => {
  if (!req.query.name) {
    res.status(400);
    return;
  }

  let response: any = null;

  switch (req.query.name) {
    case 'common-translations':
      response = commonTranslations;
      break;

    case 'contact-methods':
      response = contactMethods;
      break;

    case 'cookies-pop-up':
      response = cookiesPopUp;
      break;

    case 'country-names':
      response = countryNames;
      break;

    case 'currencies':
      response = currencies;
      break;

    case 'entities-status':
      response = entitiesStatus;
      break;

    case 'languages':
      response = languages;
      break;

    case 'main-footer':
      response = mainFooter;
      break;

    case 'main-nav-bar':
      response = mainNavBar;
      break;

    default:
      break;
  }
  res.status(200).json(response);

};

export default handler;

export const commonData = {
  commonTranslations,
  contactMethods,
  cookiesPopUp,
  countryNames,
  currencies,
  entitiesStatus,
  languages,
  mainFooter,
  mainNavBar,
}
