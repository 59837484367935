import Image from 'next/image';
import { TabSelectorStyle } from '../../../styles/components/common/ui';

export interface ITabSelectorProps {
  tabs: { name: string; label: string; iconPath: string }[];
  activeTabName?: string;
  onTabButtonClick: (tabName: string) => void;
}

const TabSelector = (props: ITabSelectorProps) => {
  return (
    <div className={TabSelectorStyle['tab-selector']}>
      {props.tabs.map((tab, index) => {
        return (
          <div
            key={index}
            onClick={() => props.onTabButtonClick(tab.name)}
            className={`${TabSelectorStyle['tab-button']} ${tab.name === props.activeTabName ? TabSelectorStyle['is-active'] : null}`}>
            <Image src={tab.iconPath} alt={tab.label} width={16} height={16} />
            <span>{tab.label}</span>
          </div>
        );
      })}
      <div className={TabSelectorStyle['tab-button']}></div>
    </div>
  );
};

export default TabSelector;

