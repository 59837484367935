import { ModalStyle } from '../../../styles/components/common/ui';
export interface IModalProps {
  show?: boolean;
  isSmall?: boolean;
  children: React.ReactNode;
}

export interface IModalSectionProps {
  children: React.ReactNode;
  className?: string;
}

const ModalHeader = (props: IModalSectionProps) => {
  return <div className={`${ModalStyle['modal-header']} ${props.className ? props.className : ''}`}>{props.children}</div>;
};

const ModalContent = (props: IModalSectionProps) => {
  return <div className={`${ModalStyle['modal-content']} ${props.className ? props.className : ''}`}>{props.children}</div>;
};

const ModalFooter = (props: IModalSectionProps) => {
  return <div className={`${ModalStyle['modal-footer']} ${props.className ? props.className : ''}`}>{props.children}</div>;
};

const Modal = (props: IModalProps) => {
  if (props.show) {
    return (
      props.show && (
        <div className={`${ModalStyle['modal']} ${ModalStyle['animate-in']} ${props.isSmall ? ModalStyle['modal-small'] : ''}`}>
          <div className={ModalStyle['modal-container']}>{props.children}</div>
        </div>
      )
    );
  } else {
    return null;
  }
};

export default Modal;
export { ModalContent, ModalFooter, ModalHeader };
