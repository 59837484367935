import React, { ReactNode } from 'react';
import FormLayout from '../layout/FormLayout';

interface CheckboxProps {
  id: string;
  label?: string | ReactNode;
  checked?: boolean;
  text?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  error?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const Checkbox = ({ name, id, onChange, checked, label, error, disabled, style }: CheckboxProps) => {
  return (
    <FormLayout
      styles={
        style
          ? {
              ...style,
              flexDirection: 'row-reverse',
              fontSize: '0.9rem',
              maxWidth: '100%',
              gap: '0.5rem',
            }
          : {
              flexDirection: 'row-reverse',
              justifyContent: 'flex-end',
              alignItems: 'center',
              fontSize: '0.9rem',
              maxWidth: '100%',
              gap: '0.5rem',
            }
      }
      labelLayout="vertical"
      label={label}
      error={error}
      id={id}>
      <input
        style={{ width: '20px', height: '20px', margin: 0 }}
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={onChange}></input>
    </FormLayout>
  );
};

export default Checkbox;
