import React from 'react';
import FormLayout from '../layout/FormLayout';
interface InputProps {
  type: 'text' | 'password' | 'email' | 'file';
  name: string;
  id: string;
  placeholder?: string;
  value?: string;
  label?: string | React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: string;
  defaultValue?: string;
  wrapper?: boolean;
  styles?: any;
  children?: React.ReactNode;
  ref?: any;
  autoFocus?: boolean;
  className?: string;
}

const Input = ({
  type,
  styles,
  name,
  id,
  placeholder,
  value,
  onChange,
  onBlur,
  disabled = false,
  error,
  defaultValue,
  label,
  children,
  wrapper,
  ref,
  autoFocus,
  className,
}: InputProps) => {
  return (
    <>
      <FormLayout styles={styles} error={error} label={children ? children : label}>
        <input
          ref={ref}
          type={type}
          name={name}
          id={id}
          style={
            error
              ? {
                  ...styles,
                  backgroundColor: '#FEF1F2',
                  borderColor: '#E18E96',
                }
              : { ...styles }
          }
          className={className}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          defaultValue={defaultValue}
          autoFocus={autoFocus}
        />
      </FormLayout>
    </>
  );
};

export default Input;

