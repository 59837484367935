import React, { forwardRef, Ref } from 'react';
import FormLayout from '../layout/FormLayout';

export interface IDropDownOption {
  value: string;
  label: string;
}

export interface IDropDownProps {
  options: IDropDownOption[];
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  label?: string;
  name?: string;
  labelLayout?: 'horizontal' | 'vertical';
  value?: string;
  id?: string;
  disabled?: boolean;
  error?: string;
  defaultValue?: string | number | undefined;
  placheholder?: string;
  style?: React.CSSProperties;
}

const DropDown = forwardRef((props: IDropDownProps, ref: Ref<any>) => {
  return (
    <FormLayout label={props.label} error={props.error}>
      <select
        ref={ref}
        id={props.id}
        name={props.name}
        value={props.value}
        style={
          props.error
            ? {
                ...props.style,
                margin: '10px 0px 0px',
                backgroundColor: '#FEF1F2',
                borderColor: '#E18E96',
              }
            : { ...props.style }
        }
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        onChange={props.onChange}>
        <option value={-1} disabled hidden>
          {props.placheholder || ''}
        </option>
        <option value={''} disabled hidden>
          {props.placheholder || ''}
        </option>
        {props.options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </FormLayout>
  );
});

DropDown.displayName = 'Dropdown';
export default DropDown;
