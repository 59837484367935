export const storeData = <T>(data: T, key: string, persist: boolean = false) => {
  try {
    if (persist) {
      localStorage.setItem(key, JSON.stringify(data));
    } else {
      sessionStorage.setItem(key, JSON.stringify(data));
    }
  } catch (error) {
    throw error;
  }
}

export const getStoredData = <T>(key: string) => {
  try {
    const value: string | null = sessionStorage.getItem(key) || localStorage.getItem(key);
    return value ? JSON.parse(value) as T : null;
  } catch (error) {
    return null;
  }
}

export const removeStoredData = (key: string) => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    throw error;
  }

  try {
    localStorage.removeItem(key);
  } catch (error) {
    throw error;
  }
}

export const existData = (key: string) => {
  try {
    return sessionStorage.getItem(key) !== null || localStorage.getItem(key) !== null;
  } catch (error) {
    return false;
  }
}
