// Radio custom component with options in typescript also group radio buttons

import FormWrapper from '../forms/FormWrapper';

interface RadioProps {
  options: {
    label: string;
    value: string;
  }[];
  onChange: (value: string) => void;
}

const Radio = ({ options, onChange }: RadioProps) => {
  return (
    <FormWrapper label="Radio">
      {options.map((option) => (
        <label key={option.value}>
          <input
            type="radio"
            value={option.value}
            onChange={(e) => onChange(e.target.value)}
          />
          {option.label}
        </label>
      ))}
    </FormWrapper>
  );
};

export default Radio;
