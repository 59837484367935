import { ICookiesPopupProps } from '../components/common/CookiesPopUp';
import { IFooterProps } from '../components/common/layout/Footer';
import { INavBarProps } from '../components/common/layout/NavBar';
import { InternalApiDataName } from '../models/app.models';
import { ICommonTranslations, IContactMethod, ICookiesPopup, ICountry, ICulture, ICurrency, IEntitiesStatus, ILanguage, IMainFooter, IMainNavBar } from '../models/strapi.models';
import { commonData } from '../pages/api/common';
import { fetchAPI, FetchParams, fetchStrapi } from '../services/api.service';
import { logInfo } from '../services/log.service';
import { getStoredData } from '../services/storage.service';
import { flatStrapiCollection, flatStrapiObject, getTranslation } from './useUtils';

export const buildApiKey = process.env.NEXT_PUBLIC_STRAPI_BUILD_API_KEY || '';
export const publicApiKey = process.env.NEXT_PUBLIC_STRAPI_API_KEY || '';
const publicWebsiteUrl = process.env.NEXT_PUBLIC_WEBSITE_URL || 'http://localhost:3000';

const internalApiParams = (name: InternalApiDataName): FetchParams => {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
    options: {
      method: 'GET',
    },
    urlParamsObject: { name },
  };
}

// Localization
export const getAppLanguages = async () => {
  let response;

  try {
    response = await fetchAPI<any>(`${publicWebsiteUrl}/api/common`, '/api/common', internalApiParams('languages'));
  } catch (error) {
    response = commonData.languages;
  }

  return flatStrapiCollection<any>({ data: response }) as ILanguage[];
};

export const getDefaultLocale = (routerLocale: string, storedLocale?: string) => {
  // If no anything else to change, use browser locale = router locale
  let defaultLocale = routerLocale;
  let defaultLocaleMessage = 'Using router locale';

  if (!!storedLocale && routerLocale !== storedLocale) {
    defaultLocale = storedLocale;
    defaultLocaleMessage = 'Using the stored locale value';
  }

  if (routerLocale !== storedLocale) {
    // URL has a explicit locale
    defaultLocale = routerLocale;
    defaultLocaleMessage =
      'There is a stored value but URL has a explicit locale. Using router locale';
  }

  logInfo([defaultLocaleMessage, `"${defaultLocale}"`], 'App → Default locale');

  return defaultLocale;
};

// Currency
export const getAppCurrencies = async () => {
  let response;

  try {
    response = await fetchAPI<any>(`${publicWebsiteUrl}/api/common`, '/api/common', internalApiParams('currencies'));
  } catch (error) {
    response = commonData.currencies;
  }
  return flatStrapiCollection<any>({ data: response }) as ICurrency[];
};

export const getDefaultCurrency = async (currencies: ICurrency[] | undefined, apiKey: string = publicApiKey) => {
  let defaultCurrencyIsoCode = 'USD';
  const storedCurrencyIsoCode = getStoredData<{ code: string }>(
    'user-currency'
  )?.code;

  if (storedCurrencyIsoCode) {
    defaultCurrencyIsoCode = storedCurrencyIsoCode;
  } else {
    const culture = await fetchStrapi<ICulture>(`/users-info/culture`, undefined, apiKey);
    const cultureCurrency = currencies?.find(
      (c) => c.isoCode === culture?.currencyCode
    );

    if (!!cultureCurrency && !!cultureCurrency.isoCode && defaultCurrencyIsoCode !== cultureCurrency.isoCode) {
      defaultCurrencyIsoCode = cultureCurrency.isoCode;
    }
  }

  const defaultCurrency = currencies?.find(
    (x) => x.isoCode === defaultCurrencyIsoCode
  );

  return defaultCurrency || undefined;
};

// Layout
export const getMainNavBar = async (locale: string) => {
  let response;

  try {
    response = await fetchAPI<any>(`${publicWebsiteUrl}/api/common`, '/api/common', internalApiParams('main-nav-bar'));
  } catch (error) {
    response = commonData.mainNavBar;
  }

  const localizedMainNavBars = response.map((x: any) => ({
    locale: x.locale,
    data: flatStrapiObject<IMainNavBar>(x),
  }));
  return localizedMainNavBars.find((x: any) => x.locale === locale)?.data || localizedMainNavBars[0].data;
};

export const getMainFooter = async (locale: string) => {
  let response;

  try {
    response = await fetchAPI<any>(`${publicWebsiteUrl}/api/common`, '/api/common', internalApiParams('main-footer'));
  } catch (error) {
    response = commonData.mainFooter;
  }

  const localizedMainFooters = response.map((x: any) => ({
    locale: x.locale,
    data: flatStrapiObject<IMainFooter>(x),
  }));
  return localizedMainFooters.find((x: any) => x.locale === locale)?.data || localizedMainFooters[0].data;
};

export const getContactMethods = async () => {
  let response;

  try {
    response = await fetchAPI<any>(`${publicWebsiteUrl}/api/common`, '/api/common', internalApiParams('contact-methods'));
  } catch (error) {
    response = commonData.contactMethods;
  }

  return flatStrapiCollection<any>({ data: response }) as IContactMethod[];
};

export const getCookiesPopup = async (locale: string) => {
  let response;

  try {
    response = await fetchAPI<any>(`${publicWebsiteUrl}/api/common`, '/api/common', internalApiParams('cookies-pop-up'));
  } catch (error) {
    response = commonData.cookiesPopUp;
  }

  const localizedCookiesPopUps = response.map((x: any) => ({
    locale: x.locale,
    data: flatStrapiObject<ICookiesPopup>(x),
  }));

  return localizedCookiesPopUps.find((x: any) => x.locale === locale)?.data || localizedCookiesPopUps[0].data;
};

export const getCookiesAcceptance = async () => {
  const acceptance = getStoredData('cookies-acceptance') as {
    accepted: boolean;
  };

  return !!acceptance ? acceptance.accepted : false;
};

export const getCommonTranslations = async () => {
  let response;

  try {
    response = await fetchAPI<any>(`${publicWebsiteUrl}/api/common`, '/api/common', internalApiParams('common-translations'));
  } catch (error) {
    response = commonData.commonTranslations;
  }

  return flatStrapiObject<ICommonTranslations>({ data: response });
};

export const getEntitiesStatus = async () => {
  let response;

  try {
    response = await fetchAPI<any>(`${publicWebsiteUrl}/api/common`, '/api/common', internalApiParams('entities-status'));
  } catch (error) {
    response = commonData.entitiesStatus;
  }

  return flatStrapiObject<IEntitiesStatus>({ data: response });
};

export const getCountryNames = async (locale: string): Promise<{
  code: string;
  name: string;
}[]> => {
  let response;

  try {
    response = await fetchAPI<any>(`${publicWebsiteUrl}/api/common`, '/api/common', internalApiParams('country-names'));
  } catch (error) {
    response = commonData.countryNames;
  }

  const countryNamesData = flatStrapiCollection<any>({ data: response }) as ICountry[];
  return countryNamesData.map((x) => ({
    code: x.iso,
    name: getTranslation(x.nameTranslations, locale)?.text || '',
  }));
}

// Aggregated props
export const populateNavBarProps = async (locale: string) => {
  const appLanguages = await getAppLanguages();
  const appCurrencies = await getAppCurrencies();
  const mainNavBar = await getMainNavBar(locale);
  const navBarProps: INavBarProps = {
    items: mainNavBar,
    appPages: [],
    appLanguages,
    appCurrencies,
    appLocale: locale,
    canShow: true,
  };

  return navBarProps;
}

export const populateCookiesPopUpProps = async (locale: string) => {
  const cookiesPopup = await getCookiesPopup(locale);
  const cookiesPopUpProps: ICookiesPopupProps = {
    message: cookiesPopup.message,
    primaryButtonLabel: cookiesPopup.primaryButtonLabel,
    secondaryButtonLabel: cookiesPopup.secondaryButtonLabel,
  }

  return cookiesPopUpProps;
}

export const populateFooterProps = async (locale: string) => {
  const footer = await getMainFooter(locale);
  const allContactMethods = await getContactMethods();
  const footerProps: IFooterProps = {
    items: footer,
    contactMethods: allContactMethods.map((x) => {
      const label = getTranslation(
        x.labelTranslations,
        locale
      );
      const value = getTranslation(
        x.valueTranslations,
        locale
      );

      return `${label.text} ${value ? value.text : ''}`;
    })
  };

  return footerProps;
}
