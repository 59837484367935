import Image from 'next/image';
import { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FixedSizeListProps, FixedSizeList as _FixedSizeList } from 'react-window';
import { CountryCode } from '../../../models/app.models';
import { decomposePhoneNumber, getCountryCallingCode, getCountryCodes, getFlagPath } from '../../../shared/useUtils';
import { FormLayoutStyle } from '../../../styles/components/common/layout';
import FormLayout from '../layout/FormLayout';
import Input from './Input';

interface DropDownPhoneOptions {
  countryCode: CountryCode;
  countryName: string;
  countryDialCode: string;
}

interface DropDownPhoneProps {
  id: string;
  label?: string;
  onChange: (value: string) => void;
  setValue?: (value: string) => void;
  name: string;
  options: DropDownPhoneOptions[];
  value?: string;
  error?: string;
  ref?: any;
  countryNames: {
    code: string;
    name: string;
  }[];
}

const FixedSizeList = _FixedSizeList as ComponentType<FixedSizeListProps>;

const DropDownPhone = (props: DropDownPhoneProps) => {
  const { onChange, label, options, error, value, ref, countryNames } = props;
  const reflocal = useRef<any>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<CountryCode>('US');
  const [dialCode, setDialCode] = useState(getCountryCallingCode('US'));
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const phoneCodes = useMemo(() => {
    return (
      getCountryCodes().map((country) => ({
        countryCode: country,
        countryDialCode: `+${getCountryCallingCode(country)}`,
        countryName: countryNames?.find((countryName) => countryName.code === country)?.name || '',
      })) as DropDownPhoneOptions[]
    ).filter((phoneCode) => phoneCode.countryName);
  }, [countryNames]);

  const handleCountryChange = useCallback((dropDownPhoneOptions: DropDownPhoneOptions) => {
    // Set the country code, dial code and search text
    setCountryCode(dropDownPhoneOptions.countryCode);
    setDialCode(dropDownPhoneOptions.countryDialCode);

    // Reset the dropdown
    setSearchText('');
    setShowDropdown(false);
  }, []);

  const onPhoneNumberChange = useCallback(
    (phoneNumber: string) => {
      try {
        setPhoneNumber(phoneNumber);
        onChange(`${dialCode}${phoneNumber}`);
      } catch (e) {
        console.log(e);
      }
    },
    [dialCode, onChange]
  );

  const filteredPhoneCodes = useMemo(() => {
    if (!searchText) return phoneCodes;
    return phoneCodes.filter((phoneCode) => {
      const name = phoneCode?.countryName?.toLowerCase();
      const code = phoneCode.countryCode.toLowerCase();
      const searchTerm = searchText.toLowerCase();
      return name?.includes(searchTerm) || code.includes(searchTerm);
    });
  }, [searchText, phoneCodes]);

  useEffect(() => {
    if (showDropdown) {
      document.getElementById('countrySearch')?.focus();
    }
  }, [showDropdown]);

  useEffect(() => {
    if (value && !initialized) {
      const { dialCode, phoneNumber, countryCode } = decomposePhoneNumber(value);
      setDialCode(dialCode);
      setPhoneNumber(phoneNumber);
      setCountryCode(countryCode || 'US');
      setInitialized(true);
    }
  }, [initialized, value]);

  useEffect(() => {
    // Register click outside event
    const clickedOut = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (showDropdown && reflocal.current && !reflocal.current.contains(e.target) && target.id !== 'countrySearch') {
        setShowDropdown(false);
      }
    };
    document.addEventListener('click', clickedOut);
    return () => {
      document.removeEventListener('click', clickedOut);
    };
  }, [showDropdown, reflocal]);

  return (
    <FormLayout labelLayout="horizontal" error={error} label={label}>
      <div
        role="combobox"
        aria-haspopup="listbox"
        aria-expanded={showDropdown}
        aria-owns="countryDropdownList"
        aria-controls="phonevalue"
        ref={reflocal}
        className={FormLayoutStyle['dropdownSelect']}
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}>
        <div className={FormLayoutStyle.flagContainer}>
          <Image alt={`country-flag-${countryCode}`} src={getFlagPath(countryCode)} fill sizes="25px" />
        </div>
        {dialCode}
      </div>
      {showDropdown && (
        <div role="listbox" id="countryDropdownList" className={FormLayoutStyle['countryDropdown']}>
          <Input
            type="text"
            name="countrySearch"
            id="countrySearch"
            placeholder="Search country"
            aria-label="Search country"
            styles={{ borderRadius: '5px', margin: '0px' }}
            aria-autocomplete="list"
            className={FormLayoutStyle['countryDropdownSearch']}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <FixedSizeList
            itemCount={filteredPhoneCodes.length}
            itemSize={33}
            width={'100%'}
            height={Math.min(filteredPhoneCodes.length * 40, 150)}
            layout="vertical"
            itemData={filteredPhoneCodes}
            className={FormLayoutStyle['countryDropdownList']}>
            {({ index, style }) => (
              <li
                style={style}
                className={FormLayoutStyle['countrySelect']}
                key={filteredPhoneCodes[index].countryCode}
                onClick={() => {
                  handleCountryChange(filteredPhoneCodes[index]);
                }}
                data-value={filteredPhoneCodes[index].countryDialCode}>
                <div className={FormLayoutStyle['countryDetails']}>
                  <div className={FormLayoutStyle['flagContainer']}>
                    <Image alt={`country-flag-${filteredPhoneCodes[index].countryCode}`} src={getFlagPath(filteredPhoneCodes[index].countryCode)} fill sizes="25px" />
                  </div>
                  <div>{filteredPhoneCodes[index].countryName}</div>
                </div>
                <div className={FormLayoutStyle['countryDialCode']}>{filteredPhoneCodes[index].countryDialCode}</div>
              </li>
            )}
          </FixedSizeList>
        </div>
      )}

      <Input
        type="text"
        name="phonevalue"
        id="phonevalue"
        ref={ref}
        value={phoneNumber}
        styles={
          error
            ? {
                backgroundColor: '#FEF1F2',
                borderColor: '#E18E96',
              }
            : {}
        }
        placeholder="Phone number"
        aria-label="Phone number"
        onChange={(e: any) => {
          onPhoneNumberChange(e.target.value);
        }}
      />
    </FormLayout>
  );
};

export default DropDownPhone;
function getCountryCodeFromDialCode(phoneNumber: string): any {
  throw new Error('Function not implemented.');
}
