import { FormLayoutStyle } from '../../../styles/components/common/layout';

export interface IFormLayout {
  children: React.ReactNode;
  label?: string | React.ReactNode;
  labelLayout?: 'horizontal' | 'vertical';
  styles?: React.CSSProperties;
  id?: string;
  error?: string;
}

const FormLayout = ({
  children,
  label,
  styles,
  id,
  error,
  labelLayout = 'vertical',
}: IFormLayout) => {
  return (
    <div>
      <div className={FormLayoutStyle.formLayout} style={styles}>
        {label && <label htmlFor={id}>{label}</label>}
        {children && (
          <div
            className={FormLayoutStyle['inputWrapper']}
            style={
              labelLayout === 'vertical'
                ? { flexDirection: 'column' }
                : { flexDirection: 'row' }
            }>
            {children}
          </div>
        )}
      </div>
      {error && (
        <div className={FormLayoutStyle['formError']}>
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

export default FormLayout;
