import React from 'react';
import { HighlightedTitleStyle } from '../../../styles/components/common/ui';
export interface IToastProps {
  text: string;
  highlightedText: string;
  headingTagName?: string;
}

const DynamicHeading = ({ headingTagName, highlightedText, children }: any) => {
  const Tag = headingTagName || 'h1';
  const textParts = children.split(highlightedText);
  return React.createElement(
    Tag,
    { className: HighlightedTitleStyle['highlighted-title'] },
    textParts[0],
    ' ',
    React.createElement('span', { className: HighlightedTitleStyle['highlighted-title-colored-text'] }, highlightedText),
    ' ',
    textParts[1]
  );
};

const HighlightedTitle = (props: IToastProps) => {
  return <DynamicHeading {...props}>{props.text}</DynamicHeading>;
};

export default HighlightedTitle;
