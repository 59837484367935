// Create a form wrapper component to wrap the input component typescript

import { FormWrapperStyle } from '../../../styles/components/common/forms';

interface FormWrapperProps {
  children: React.ReactNode;
  label?: string;
  labelLayout?: 'horizontal' | 'vertical';
  styles?: React.CSSProperties;
  id?: string;
}

const FormWrapper = ({
  children,
  label,
  styles,
  id,
  labelLayout = 'vertical',
}: FormWrapperProps) => {
  return (
    <div className={FormWrapperStyle.formWrapper} style={styles}>
      {label && <label htmlFor={id}>{label}</label>}
      {children && (
        <div
          className={FormWrapperStyle['inputWrapper']}
          style={
            labelLayout === 'vertical'
              ? { flexDirection: 'column' }
              : { flexDirection: 'row' }
          }>
          {children}
        </div>
      )}
    </div>
  );
};

export default FormWrapper;
