import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { ContentSidebarBookmarksStyle } from '../../../styles/components/common/ui';

export interface IContentSidebarBookmarks {
  contentElementId: string;
  bookmarkElementTagName: string;
}

export interface IComponentState {
  bookmarks?: { label: string; id: string }[];
  currentBookmarkId?: string;
}

const ContentSidebarBookmarks = (props: IContentSidebarBookmarks) => {
  const router = useRouter();
  const [state, setState] = useState<IComponentState>({
    bookmarks: undefined,
    currentBookmarkId: undefined,
  });

  const setCurrentBookmarkId = useCallback((bookmarkId: string) => {
    setState((currentState) => ({
      ...currentState,
      currentBookmarkId: bookmarkId,
    }));
  }, []);

  const setBookmarks = useCallback(
    (bookmarks: { label: string; id: string }[]) => {
      setState((currentState) => ({
        ...currentState,
        bookmarks,
      }));
    },
    []
  );

  const onBookmarkClick = (bookmarkId: string) => {
    const content = document.getElementById(props.contentElementId);
    if (content) {
      const title = content.querySelector(
        `#${bookmarkId}`
      ) as HTMLHeadingElement;

      title.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  const registerElementIntersectsViewport = useCallback(() => {
    window.addEventListener('scroll', () => {
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;

      const container = document.getElementById(props.contentElementId);

      if (container) {
        const titles = container.querySelectorAll(props.bookmarkElementTagName);
        titles.forEach((element: any) => {
          const { top, bottom } = element.getBoundingClientRect();

          if (bottom > 0 && bottom <= viewportHeight && top >= 0) {
            setCurrentBookmarkId(element.id);
          }
        });
      }
    });
  }, [
    props.bookmarkElementTagName,
    props.contentElementId,
    setCurrentBookmarkId,
  ]);

  const populateBookmarks = useCallback(() => {
    const htmlText = document.getElementById(props.contentElementId);
    if (htmlText) {
      const titleElements = htmlText.getElementsByTagName(
        props.bookmarkElementTagName
      );
      const titles = [...titleElements] as HTMLHeadingElement[];
      titles.forEach((x, index) => (x.id = `title-${index}`));
      const titleBookmarks = titles.map((x) => ({
        label: x.innerText,
        id: x.id,
      }));

      setBookmarks(titleBookmarks);
      setCurrentBookmarkId(titleBookmarks[0].id);
    }
  }, [
    props.bookmarkElementTagName,
    props.contentElementId,
    setBookmarks,
    setCurrentBookmarkId,
  ]);

  useEffect(() => {
    populateBookmarks();
    registerElementIntersectsViewport();
  }, [populateBookmarks, registerElementIntersectsViewport, router.locale]);
  return (
    <>
      <div
        className={ContentSidebarBookmarksStyle['content-sidebar-bookmarks']}>
        <ul>
          {state.bookmarks?.map((bookmark, index) => {
            return (
              <li
                key={index}
                onClick={() => onBookmarkClick(bookmark.id)}
                className={
                  state.currentBookmarkId &&
                  state.currentBookmarkId === bookmark.id
                    ? ContentSidebarBookmarksStyle['is-active']
                    : undefined
                }>
                <span>{bookmark.label}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default ContentSidebarBookmarks;
